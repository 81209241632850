<template>
    <div class="columns">
        <div class="content body-content" v-if="contentStatus">
            <div class="panel panel-body">
                <Form v-model="formData" ref="form" />
                <a href="#" :title="text.save" class="btn btn-primary" @click="save($event)" @keyup.enter="save($event)">{{text.save}}</a> <router-link :to="{ name: 'AdminMessages' }" :title="text.cancel" class="btn btn-tertiary">{{text.cancel}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";

    import {formWrapperMixin} from "@/views/mixins/formwrapper";
    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {messagesActionsMixin} from "@/views/mixins/messagesActions";

    import {mapActions, mapState} from "vuex";

    import Form from "@/components/form/form";

    export default {
        name: 'AdminMessagesEdit',
        components: {Form},
        mixins: [formWrapperMixin, formatContentMixin, messagesActionsMixin],
        data() {
            return {
                messageData: [],
                contentStatus: true,
                formData: [
                    {
                        id: 1,
                        type: 'text',
                        name: 'headline',
                        label: 'Headline',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        validate : {
                            type: 'string',
                            required: true,
                            min: 5,
                        }
                    },
                    {
                        id: 2,
                        type: 'wysiwyg',
                        name: 'body',
                        label: 'Message body',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        config : {
                            buttons : [
                                "b", "i", "a", "h", "ul", "ol"
                            ]
                        },
                        validate : {
                            type: 'string',
                            required: true,
                            min: 5,
                        }
                    },
                ]
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id;
            }
        },
        async created() {
            let result = await this.loadMessageData(this.id);

            log(["Result of page data load", result]);

            if (result.status === 200) {
                this.messageData = result.data.data;
                this.formData.find(row => row.name === 'headline').value = result.data.data.headline;
                this.formData.find(row => row.name === 'body').value = result.data.data.body;

                // Set Page Title
                this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.messageData.headline) });
            } else {
                // hide page content
                this.contentStatus = false;

                // Set Page Title
                this.setPageTitleAction({ title : this.text.content_404 });
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async save(e){
                e.preventDefault();

                log(["Save triggered by wrapper"]);

                if(this.$refs.form.save()){

                    // save updated value into helpData
                    this.messageData.headline = this.getFormDataValueByName(this.formData, "headline");
                    this.messageData.body = this.getFormDataValueByName(this.formData, "body");

                    log(["Form data message", this.messageData]);

                    let result = await this.saveMessageDataByID(this.id, this.messageData);

                    if(result.status === 200){
                        this.$router.push( { name: 'AdminMessagesList', params: { message: 'saved'} } );
                    }
                    else
                    {
                        log(["Form save result no ok"]);
                    }
                }
                else
                {
                  // TO DO SHOW ERROR
                  log(["Form is NOT ok to be saved"]);
                  this.scrollToFirstError();
                }
            },
        }
    }
</script>
