// define a mixin object
import {log} from "@/shared/utils";
import {getMessageByID, getUserMessageByID, saveMessageByID, getUserNextUnreadMessages, getUserUnreadMessagesList, markMessageAsReadByID, getUserMessagesList, getUserMessagesListArchive, getAdminMessagesList, getAdminMessagesPreviewList, getAdminMessagesListArchive, addMessage, deleteMessageByID, archiveMessageByID} from "@/shared/dataservice";
import {showDefaultServerErrorMessage} from "@/shared/utils";
import lang from "@/shared/lang";

export const messagesActionsMixin = {
    data() {
        return {
            unreadMessage: {
                message: {},
                total:0
            },
            unreadMessages: {
                messages:[],
                total:0
            }
        }
    },
    methods: {
        async loadUserNextUnreadMessage() {
            let result = await getUserNextUnreadMessages();

            log(["Loaded USER next unread Message", result]);

            if(result.status === 200) {
                if(result.data.data.length > 0){
                    this.unreadMessage.message = result.data.data[0];
                    this.unreadMessage.total = result.data.meta.totalRecords;
                }
                else
                {
                    this.unreadMessage.message = {};
                    this.unreadMessage.total = 0;
                }
            }
            else{
                showDefaultServerErrorMessage("Load next unread message");
            }

            this.activeTablePagination = result.data.meta;
        },
        async loadUserUnreadMessages() {
            let result = await getUserUnreadMessagesList();

            log(["Loaded USER unread Messages", result]);

            if(result.status === 200) {
                if(result.data.data.length > 0){
                    this.unreadMessages.messages = result.data.data;
                    this.unreadMessages.total = result.data.meta.totalRecords;
                }
                else
                {
                    this.unreadMessages.messages = [];
                    this.unreadMessages.total = 0;
                }
            }
            else{
                showDefaultServerErrorMessage("Load next unread message");
            }

            this.activeTablePagination = result.data.meta;
        },
        async loadUserActiveMessagesList(page = 1) {
            let result = await getUserMessagesList(page);

            log(["Loaded USER active Message list", page, result]);

            this.activeTableData = result.data.data;
            this.activeTablePagination = result.data.meta;
        },
        async loadUserArchiveMessagesList(page = 1) {
            let result = await getUserMessagesListArchive(page);

            log(["Loaded USER archive Message list", page, result]);

            this.archiveTableData = result.data.data;
            this.archiveTablePagination = result.data.meta;
        },
        async loadAdminActiveMessagesList(page = 1) {
            let result = await getAdminMessagesList(page);

            log(["Loaded ADMIN active Message list", page, result]);

            this.activeTableData = result.data.data;
            this.activeTablePagination = result.data.meta;
        },
        async loadAdminActiveMessagesPreviewList(numberPerPage = 10) {
            let result = await getAdminMessagesPreviewList(numberPerPage);

            log(["Loaded ADMIN active Message preview list", numberPerPage, result]);

            this.activeTableData = result.data.data;
        },
        async loadAdminArchiveMessagesList(page = 1) {
            let result = await getAdminMessagesListArchive(page);

            log(["Loaded ADMIN archive Message list", page, result]);

            this.archiveTableData = result.data.data;
            this.archiveTablePagination = result.data.meta;
        },
        async loadMessageData(id) {
            log(["Loading message data", id]);
            return getMessageByID(id);
        },
        async loadUserMessageData(id) {
            log(["Loading user message data", id]);
            return getUserMessageByID(id);
        },
        async saveMessageDataByID(id, data) {
            log(["Loading message data", id, data]);
            return saveMessageByID(id, data);
        },
        async addMessageData(data) {
            log(["Add message data", data]);
            return addMessage(data);
        },
        /**
         * ACTIONS
         */
        markMessageAsRead: async function (id){
            let result = await markMessageAsReadByID(id);

            log(["Mark Message as read", id, result]);

            if(result.status === 200) {
                return true;
            }
            else{
                showDefaultServerErrorMessage("Mark message as read");
            }
        },
        deleteMessage: async function (id, successMethodName) {
            let confirm = window.confirm(lang.message_confirm_message_delete);

            if(confirm){
                let result = await deleteMessageByID(id);

                log(["Delete Message", id, result]);

                if(result.status === 200){
                    this[successMethodName]();
                }
                else
                {
                    showDefaultServerErrorMessage("Delete Message");
                }
            }
        },
        archiveMessage: async function (id, successMethodName){
            let confirm = window.confirm(lang.message_confirm_message_delete);

            if(confirm){
                let result = await archiveMessageByID(id);

                log(["Archive Message", id, result]);

                if(result.status === 200){
                    this[successMethodName]();
                }
                else
                {
                    showDefaultServerErrorMessage("Archive Message");
                }
            }

        }
    },
}