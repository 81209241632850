<template>
    <div class="columns">
        <div class="content body-content" v-if="contentStatus">
            <div class="mb-12">
                <p class="message-date">{{text.message_date}}: {{messageData.messageDate | formatDateTime}}</p>
                <span v-html="messageData.body"></span>
            </div>
            <router-link :to="{ name: 'AdminMessagesEdit', params: { id : id } }" :title="text.edit + ' ' + messageData.headline" class="btn" v-if="!messageData.archived">{{text.edit_this_message}}</router-link>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {messagesActionsMixin} from "@/views/mixins/messagesActions";

    import {mapActions, mapState} from "vuex";

    export default {
        name: 'AdminMessagesView',
        mixins: [formatContentMixin, messagesActionsMixin],
        data() {
            return {
                messageData: {
                    messageDate: "2020-01-01T01:00:01"
                },
                contentStatus: true,
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang;
            },
            id() {
                return this.$route.params.id;
            }
        },
        async created() {
            let result = await this.loadMessageData(this.id);

            log(["Result of page data load", result]);

            if (result.status === 200) {
                this.messageData = result.data.data;

                // Set Page Title
                this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.messageData.headline) });
            } else {
                // hide page content
                this.contentStatus = false;

                // Set Page Title
                this.setPageTitleAction({ title : this.text.content_404 });
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
        }
    }
</script>